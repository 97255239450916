/**
 * Footer component.
 * @module components/theme/Footer/Footer
 *
 * added logo link-image
 */

import React from 'react';
import { Container, List, Segment, Grid, Image, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import LiveChat from 'react-livechat';
import LogoModena from './LogoComuneModena.png';
import LogoModenaCity from './LogoAffiancatoCity.png';
import LoghettiModena from './logo-unesco-nuovo.png';
import LogoERT_it from './LogoERTurismo-it.png';
import LogoERT_en from './LogoERTurismo-en.png';
import LogoEuroparc from './LogoEuroparc.png';
import { SocialLinks } from 'volto-social-settings';
import { useGoogleAnalytics } from 'volto-google-analytics';
import config from '@plone/volto/registry';
import { useSelector } from 'react-redux';

import LogoImage from '@package/components/layout/images/logo/logo_primario_bianco.svg';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
  comune_modena: {
    id: 'Comune di Modena',
    defaultMessage: 'Comune di Modena',
  },
  comune_unesco: {
    id: 'Unesco - Patrimonio Mondiale',
    defaultMessage: 'Unesco - Patrimonio Mondiale',
  },
  emiliaromagnaturismo: {
    id: 'Emilia Romagna turismo',
    defaultMessage: 'Emilia Romagna turismo',
  },
  subscribe_newsletter_label: {
    id: 'Subscribe Newsletter Label',
    defaultMessage: 'Iscriviti alla nostra newsletter',
  },
  subscribe_newsletter_submit: {
    id: 'Subscribe Newsletter Submit',
    defaultMessage: 'Invia',
  },
  download_app: {
    id: 'Download App',
    defaultMessage: 'Scarica la nostra app mobile!',
  },
  organize_your_event: {
    id: 'organize your event',
    defaultMessage: 'Organizza il tuo evento',
  },
  chi_siamo: {
    id: 'Chi siamo',
    defaultMessage: 'Chi siamo',
  },
  contattaci: {
    id: 'Contattaci',
    defaultMessage: 'Contattaci',
  },
  orari: {
    id: 'Orari',
    defaultMessage: 'Orari',
  },
  regolamento: {
    id: 'Regolamento',
    defaultMessage: 'Regolamento',
  },
  sitemap: {
    id: 'Mappa del sito',
    defaultMessage: 'Mappa del sito',
  },
  dichiarazione_accessibilita: {
    id: 'Dichiarazione di accessibilità',
    defaultMessage: 'Accessibilità',
  },
  dove_siamo: {
    id: 'Dove siamo',
    defaultMessage: 'Dove siamo',
  },
  not_found: {
    id:
      'We apologize for the inconvenience, but the page you were trying to access is not at this address. You can use the links below to help you find what you are looking for.',
    defaultMessage:
      "Ci scusiamo per l'inconveniente, la pagina cui stai provando ad accedere non esiste a questo indirizzo.",
  },
  covid_link: {
    id: 'covid_link',
    defaultMessage:
      'https://www.visitmodena.it/it/pianifica-il-viaggio/covid-cosa-sapere',
  },
  covid_infos: {
    id: 'covid_infos',
    defaultMessage: 'Covid 19 - Infomazioni utili',
  },
  candidatura_unesco: {
    id: 'candidatura_unesco',
    defaultMessage: 'Candidatura Unesco - City of Media Arts',
  },
  logo_europark: {
    id: 'logo_europark',
    defaultMessage: 'Europark - Sustainable Tourism in Protected Areas',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

const Footer = ({ intl, pathname }) => {
  const locale_links = {
    chi_siamo: { it: '/chi-siamo', en: '/who-we-are' },
    contattaci: {
      it: '/pianifica-il-viaggio/contatta-lo-iat',
      en: '/plan-the-trip/contact-us',
    },
    orari: {
      it: '/pianifica-il-viaggio/contatta-lo-iat',
      en: '/plan-the-trip/contact-us',
    },
    dove_siamo: {
      it: '/dove-siamo',
      en: '/where-we-are',
    },
    brochure: {
      it: '/pianifica-il-viaggio/mappe-guide-e-pubblicazioni',
      en: '/plan-the-trip/maps-guides-and-publications',
    },
    regolamento: {
      it: '/regolamento',
      en: '/terms-of-use',
    },
  };
  const lang = useSelector((state) => state.intl.locale);
  const livechatGDPR = useSelector(
    (state) => state.gdprPrivacyConsent?.preferences?.LIVECHAT,
  );

  useGoogleAnalytics();

  return (
    <div id="footer" role="contentinfo" className="footer-wrapper">
      <Segment
        vertical
        padded
        inverted
        color="grey"
        textAlign="center"
        className="footer"
      >
        <Grid
          container
          stackable
          celled="internally"
          reversed="mobile tablet vertically"
        >
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={3}
            className="tablet-border-bottom"
          >
            <Grid celled="internally" className="loghi">
              <Grid.Row verticalAlign="middle" className="fixed-height">
                <Grid.Column>
                  <Image
                    src={LogoModena}
                    alt={intl.formatMessage(messages.comune_modena)}
                    title={intl.formatMessage(messages.comune_modena)}
                    loading="lazy"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Image
                    src={LoghettiModena}
                    alt={intl.formatMessage(messages.comune_unesco)}
                    title={intl.formatMessage(messages.comune_unesco)}
                    loading="lazy"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  {__CLIENT__ && (
                    <a
                      href={`https://www.emiliaromagnaturismo.it/${intl.locale}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={intl.locale === 'it' ? LogoERT_it : LogoERT_en}
                        alt={intl.formatMessage(messages.emiliaromagnaturismo)}
                        title={intl.formatMessage(
                          messages.emiliaromagnaturismo,
                        )}
                        loading="lazy"
                      />
                    </a>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <a
                    href={`http://www.parchiemiliacentrale.it/pagina.php?id=286`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={LogoEuroparc}
                      alt={intl.formatMessage(messages.logo_europark)}
                      title={intl.formatMessage(messages.logo_europark)}
                      loading="lazy"
                      width="150"
                    />
                  </a>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Image
                    src={LogoModenaCity}
                    alt={intl.formatMessage(messages.candidatura_unesco)}
                    title={intl.formatMessage(messages.candidatura_unesco)}
                    loading="lazy"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={4}
            textAlign="center"
            verticalAlign="middle"
          >
            <Grid celled="internally">
              {/* <Grid.Row className="fixed-height padding-top tablet-border-bottom">
                <Grid.Column>
                  {intl.formatMessage(messages.download_app)}
                  <a
                    href="https://m.visitmodena.it"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="icon-row">
                      <Icon name="download" size="big" />
                    </div>
                  </a>
                </Grid.Column>
              </Grid.Row> */}
              <Grid.Row className="padding-top">
                <Grid.Column>
                  {__CLIENT__ && (
                    <List horizontal className="footer-links" role={null}>
                      <List.Item
                        as={Link}
                        to={
                          '/' +
                          intl.locale +
                          locale_links.chi_siamo[intl.locale]
                        }
                        role={null}
                      >
                        {intl.formatMessage(messages.chi_siamo)}
                      </List.Item>
                      <List.Item
                        as={Link}
                        to={
                          '/' +
                          intl.locale +
                          locale_links.dove_siamo[intl.locale]
                        }
                        role={null}
                      >
                        {intl.formatMessage(messages.dove_siamo)}
                      </List.Item>

                      <List.Item
                        as={Link}
                        to={
                          '/' +
                          intl.locale +
                          locale_links.contattaci[intl.locale]
                        }
                        role={null}
                      >
                        {intl.formatMessage(messages.contattaci)}
                      </List.Item>

                      {/*<List.Item as={Link} to={'/' + intl.locale + '/blog'}  role={null}>
                        Blog
                      </List.Item>*/}
                      <List.Item
                        as={Link}
                        to={
                          '/' +
                          intl.locale +
                          locale_links.regolamento[intl.locale]
                        }
                        role={null}
                      >
                        {intl.formatMessage(messages.regolamento)}
                      </List.Item>
                      <List.Item
                        as={Link}
                        to={'/' + intl.locale + locale_links.orari[intl.locale]}
                        role={null}
                      >
                        {intl.formatMessage(messages.orari)}
                      </List.Item>

                      <List.Item
                        as="a"
                        href="https://form.agid.gov.it/view/b974a857-7c8c-4a69-bf8d-7cc416a8d197/"
                        rel="noopener noreferrer"
                        target="_blank"
                        role={null}
                      >
                        {intl.formatMessage(
                          messages.dichiarazione_accessibilita,
                        )}
                      </List.Item>
                      <List.Item
                        as={Link}
                        to={
                          '/' + intl.locale + locale_links.brochure[intl.locale]
                        }
                        role={null}
                      >
                        Brochure
                      </List.Item>
                      <List.Item
                        as={Link}
                        to={'/' + intl.locale + '/sitemap'}
                        role={null}
                      >
                        {intl.formatMessage(messages.sitemap)}
                      </List.Item>
                      <List.Item
                        as={Link}
                        to={'/' + intl.locale + '/privacy'}
                        role={null}
                      >
                        Privacy
                      </List.Item>
                    </List>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={5}
            className="tablet-border-bottom"
          >
            <Grid celled="internally" textAlign="left">
              <Grid.Row className="fixed-height tablet-no-border">
                <Grid.Column>
                  <div className="label semibold">
                    <Icon name="envelope outline" /> NEWSLETTER
                  </div>
                  {__CLIENT__ && (
                    <List horizontal role={null}>
                      <List.Item
                        as="a"
                        href="https://nl-turismo.comune.modena.it/user/register"
                        target="_blank"
                        rel="noopener noreferrer"
                        role={null}
                      >
                        {intl.formatMessage(
                          messages.subscribe_newsletter_label,
                        )}
                      </List.Item>
                    </List>
                  )}

                  {/* <Form className="subscribe-newsletter">

                      <label className="label" id="newsletter-label">
                        <Icon name="envelope outline" />{' '}
                        {intl.formatMessage(
                          messages.subscribe_newsletter_label,
                        )}
                      </label>

                  <Form.Group>
                      <Form.Input
                        fluid
                        placeholder="email"
                        width={10}
                        aria-labelledby="newsletter-label"
                      />
                      <Form.Button type="submit" fluid width={5}>
                        {intl.formatMessage(
                          messages.subscribe_newsletter_submit,
                        )}
                      </Form.Button>
                    </Form.Group>
                  </Form> */}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="padding-top tablet or lower hidden">
                <Grid.Column>
                  <div className="label semibold">
                    <Icon name="suitcase" /> MODENA BUSINESS
                  </div>
                  {__CLIENT__ && (
                    <List horizontal role={null}>
                      <List.Item
                        as={Link}
                        to={'/' + intl.locale + '/business'}
                        role={null}
                      >
                        {intl.formatMessage(messages.organize_your_event)}
                      </List.Item>
                      {/* <List.Item as="a" href="https://www.google.it"  role={null}>
                    Meetings
                  </List.Item>
                  <List.Item as="a" href="https://www.google.it"  role={null}>
                    Servizi e strutture
                  </List.Item>
                  <List.Item as="a" href="https://www.google.it"  role={null}>
                    Trasporti
                  </List.Item> */}
                    </List>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </Segment>

      {/** Yellow banner covid  */}

      {/* <Segment
        vertical
        padded
        inverted
        color="yellow"
        textAlign="center"
        className="covid-infos"
      >
        <Container>
          <Image
            src={require(`@package/components/Footer/icon-covid-19.svg`)}
            alt={intl.locale}
            title={intl.locale}
            loading="lazy"
          />
          <div>
            <a href={intl.formatMessage(messages.covid_link)}>
              {intl.formatMessage(messages.covid_infos)}
            </a>
          </div>
        </Container>
      </Segment> */}
      <Segment vertical padded secondary textAlign="center" className="footer">
        <Container>
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column
                textAlign="left"
                mobile={12}
                tablet={12}
                computer={4}
              >
                <div className="brand">
                  <Link to={config.settings.isMultilingual ? `/${lang}` : '/'}>
                    <Image
                      src={LogoImage}
                      alt="Visit Modena"
                      title="Visit Modena"
                    />
                  </Link>
                  <div className="infos">
                    <FormattedMessage
                      id="SitePayoff"
                      defaultMessage="Sito Ufficiale di Informazione Turistica di Modena"
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                mobile={12}
                tablet={12}
                computer={4}
                className="tablet-border-bottom"
                verticalAlign="middle"
              >
                <SocialLinks
                  wrapperCssClass="ui horizontal list socials"
                  itemCssClass="item"
                />
              </Grid.Column>
              <Grid.Column
                verticalAlign="middle"
                mobile={12}
                tablet={12}
                computer={4}
                className="language"
                textAlign="right"
              >
                <FormattedMessage id="Language" defaultMessage="Lingua" />{' '}
                {intl.locale}
                {__CLIENT__ && (
                  <Image
                    src={require(`@package/components/Footer/Languages/${intl.locale}.svg`)}
                    alt={intl.locale}
                    title={intl.locale}
                    loading="lazy"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        {__CLIENT__ && livechatGDPR && <LiveChat license={14943486} />}
      </Segment>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
